import cx from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import * as styles from './Share.module.css';

import LinkedIn from '@components/SVG/LinkedIn';
import Twitter from '@components/SVG/Twitter';

const Share = ({
	url,
	title,
	as: Component = 'div',
	className = '',
}: {
	url: string;
	title: string;
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	return (
		<Component
			className={cx(styles.bounds, {
				[className]: !!className,
			})}>
			<div className={styles.share}>
				<motion.p
					className={styles.copy}
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true, amount: 0.6 }}
					transition={{ duration: 0.35, delay: 0.05 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 },
					}}>
					Share on:
				</motion.p>

				<ul className={styles.list}>
					<motion.li
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true, amount: 0.6 }}
						transition={{ duration: 0.35, delay: 0.25 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}>
						<a
							href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}`}
							target="_blank"
							rel="noopener">
							<LinkedIn className={styles.icon} />
						</a>
					</motion.li>
					<motion.li
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true, amount: 0.6 }}
						transition={{ duration: 0.35, delay: 0.5 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}>
						<a
							href={`http://twitter.com/share?text=${title}&url=${url}`}
							target="_blank"
							rel="noopener">
							<Twitter className={styles.icon} />
						</a>
					</motion.li>
				</ul>
			</div>
		</Component>
	);
};

export default Share;
