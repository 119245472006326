import { LINKS } from '@utils/constants';
import React from 'react';

import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Breadcrumbs from '@components/Molecule/Breadcrumbs/Breadcrumbs';
import Button from '@components/Molecule/Button/Button';
import Share from '@components/Molecule/Share/Share';
import Marker from '@components/SVG/Marker';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';

const CareersTemplate = ({ pageContext }: { pageContext: any }) => {
	const { crumbs } = pageContext.breadcrumb;

	const { title, location, content } = pageContext.job.data;

	return (
		<Page>
			<SEO title={`${title?.text && title.text} | UHV Design`} />

			<PageSection>
				<Breadcrumbs crumbs={crumbs} />

				{title?.text && (
					<H level={2} as="h1">
						{title.text}
					</H>
				)}
			</PageSection>

			<PageSection>
				<Share title={title?.text} url={'/'} />

				<Content constrict>
					{location && (
						<div className="row">
							<Marker />
							<Copy font="sm" weight="bold" spacing="none">
								{location}
							</Copy>
						</div>
					)}

					{content?.html && (
						<div
							dangerouslySetInnerHTML={{
								__html: content.html,
							}}></div>
					)}
				</Content>
			</PageSection>

			<PageSection>
				<Grid>
					<Button to={LINKS.CAREERS} theme="ghost" align="center">
						Back to Careers
					</Button>

					<Button to="mailto:sales@uhvdesign.com" align="center">
						Apply via e-mail
					</Button>
				</Grid>
			</PageSection>
		</Page>
	);
};

export default CareersTemplate;
