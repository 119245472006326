import React from 'react';

const Twitter = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 34 34">
			<path fill="none" d="M0 0h34v34H0z" />
			<path
				d="M30.6 34H3.4A3.4 3.4 0 0 1 0 30.6V3.4A3.4 3.4 0 0 1 3.4 0h27.2A3.4 3.4 0 0 1 34 3.4v27.2a3.4 3.4 0 0 1-3.4 3.4ZM5.1 24.229a14.214 14.214 0 0 0 7.484 2.119 13.558 13.558 0 0 0 13.893-13.43c0-.2 0-.409-.014-.61A9.734 9.734 0 0 0 28.9 9.864a10.045 10.045 0 0 1-2.8.744A4.754 4.754 0 0 0 28.243 8a10 10 0 0 1-3.1 1.146 4.982 4.982 0 0 0-3.565-1.496 4.809 4.809 0 0 0-4.878 4.72 4.614 4.614 0 0 0 .125 1.076A14.075 14.075 0 0 1 6.757 8.513a4.613 4.613 0 0 0-.657 2.375 4.676 4.676 0 0 0 2.171 3.929 5.019 5.019 0 0 1-2.211-.591v.06a4.773 4.773 0 0 0 3.917 4.628A5.082 5.082 0 0 1 7.768 19a4.864 4.864 0 0 0 4.561 3.279A9.943 9.943 0 0 1 6.265 24.3a10.156 10.156 0 0 1-1.165-.071Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default Twitter;
